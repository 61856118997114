import React from "react";
import "../footer/bottomFooter.css";

const BottomFooter = () => {
  return (
    <div className="bottom_footer_wrapper">
      <div className="bottom_footer_inner_div">
        <p className="bottom_footer_copyright_p">© 2024 All Rights Reserved</p>
        <div className="bottom_footer_policy_div">
          <p>Privacy Policy</p>
          <p>Terms of Use</p>
        </div>
      </div>
    </div>
  );
};

export default BottomFooter;
