// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bold_text_wrapper {
  width: 80%;
  margin: 150px auto;
}

.bold_text_p {
  color: #666768;
  font-family: Inter;
  font-size: clamp(20px, 6vw, 100px) !important;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  background-image: linear-gradient(180deg, #fff 50%, #666768 50%);
  background-size: 100% 200%;
  background-position: 0 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bold_text_p.animate {
  animation: gradientAnimation 4s ease forwards;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@media screen and (max-width: 600px) {
  .bold_text_wrapper {
    width: 100%;
    margin: 10px auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/aboutUs/aboutUs.css"],"names":[],"mappings":"AACA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,6CAA6C;EAC7C,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gEAAgE;EAChE,0BAA0B;EAC1B,2BAA2B;EAC3B,qBAAqB;EACrB,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,WAAW;IACX,iBAAiB;EACnB;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap\");\n.bold_text_wrapper {\n  width: 80%;\n  margin: 150px auto;\n}\n\n.bold_text_p {\n  color: #666768;\n  font-family: Inter;\n  font-size: clamp(20px, 6vw, 100px) !important;\n  font-style: normal;\n  font-weight: 900;\n  line-height: normal;\n  background-image: linear-gradient(180deg, #fff 50%, #666768 50%);\n  background-size: 100% 200%;\n  background-position: 0 100%;\n  background-clip: text;\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.bold_text_p.animate {\n  animation: gradientAnimation 4s ease forwards;\n}\n\n@keyframes gradientAnimation {\n  0% {\n    background-position: 0 100%;\n  }\n  100% {\n    background-position: 0 0;\n  }\n}\n@media screen and (max-width: 600px) {\n  .bold_text_wrapper {\n    width: 100%;\n    margin: 10px auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
