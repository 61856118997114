import Header from "../header/Header";
import './pageHead.css';
function PageHead({ heading, paraOne, image_url, paraTwo, paraThree, email }) {
    return (
        <>
            <Header />
            <div className="pageHeadContainer">
                <div>
                    <label>{heading}
                    </label>
                    <div></div>

                </div>
                {paraOne !== undefined && <p>{paraOne}</p>}
                {image_url!==undefined && <img src={image_url} alt="image" />}
                <p>{paraTwo}</p>
                <p>{paraThree} <a href="mailto:recipient@example.com">{email !== undefined && <span>{email}</span>}</a>
                </p>

            </div>
        </>
    )
}
export default PageHead;