import React from "react";
import "../finisher/finisher.css";

const Finisher = () => {
  return (
    <div className="finisher_wrapper">
      <div className="finisher_heading_div">
        <p className="finisher_heading">FINISHER</p>
      </div>
      <div className="finisher_para_div">
        <p>
          But perhaps the most incredible aspect of the human spirit is its
          boundless potential for growth and transformation. We are not defined
          by our past or our circumstances; rather, we are shaped by our choices
          and our actions. Every setback is an opportunity for growth, every
          failure a lesson in perseverance. With each challenge we overcome, we
          become stronger, more resilient, more capable of pushing beyond our
          limits.
        </p>
        <p>
          In a race format where individuals compete until only one emerges
          victorious, the manifestation of the human spirit is particularly
          profound. This endurance challenge becomes a microcosm of life's
          struggles, where competitors confront their innermost fears, doubts,
          and physical limitations. As the race progresses, participants are
          pushed to the brink, testing the depths of their resilience and
          determination.
        </p>
        <p>
          Ultimately, as the race nears its conclusion, only one competitor
          remains standing, a testament to the power of the human spirit to
          triumph in the face of adversity. Their victory is not just a personal
          achievement but a testament to the indomitable nature of the human
          spirit itself. In the end, the race serves as a powerful reminder that
          we are capable of overcoming any obstacle, as long as we have the
          courage to keep pushing forward.
        </p>
      </div>
    </div>
  );
};

export default Finisher;
