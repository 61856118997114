import React from "react";
import Slider from "react-slick";
import "../valour/valour.css";

const Valour = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    swipeToSlide: true,
    arrows: false,
    draggable: false,
    pauseOnHover: false,
    fade: true,
  };
 
  return (
    <div className="valour_wrapper">
      <div className="valour_slider_main">
        <Slider {...settings}>
          <div className="valour_card">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/img1+(6).png" alt="img" />
          </div>
          <div className="valour_card">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/img2+(3).png" alt="img" />
          </div>
          <div className="valour_card">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/img3+(2).png" alt="img" />
          </div>
        </Slider>
      </div>
      <img className="valour_overlay" src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/overlay.png" alt="img" />
      <div className="valpour_text_div">
        <div className="valour_text_inner_div">
          <p className="valour_text_1">Stories of valour </p>
          <p className="valour_text_2">Become an inspiration </p>
          <p className="valour_text_3">
            The athletes who compete in The One Finisher, deserve their stories
            told to the rest of the world These are inspirational individuals
            who are prepared to run an extraordinary race, our crew will follow
            the journey of the final 20 as they break the limits of endurance
            and strive for the final spot.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Valour;
