import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./register.css";
import register from "../../assets/register.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Tick from "../../assets/Tick.gif";

const Register = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone Number must be exactly 10 digits")
      .required("Phone Number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    city: Yup.string().required("City is required"),
    dob: Yup.date().required("Date of Birth is required"),
    runningYears: Yup.number().required("Years of running is required"),
    longestDistance: Yup.number().required("Longest distance is required"),
    reasonToParticipate: Yup.string().required(
      "Reason to participate is required"
    ),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    city: "",
    dob: "",
    runningYears: "",
    longestDistance: "",
    reasonToParticipate: "",
  };

  const [showThankYou, setShowThankYou] = useState(false);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post(
        "https://submit-form.com/pfb9N6PtH",
        values
      );
      console.log(response.data);
      setShowThankYou(true);
      setTimeout(() => {
        setShowThankYou(false);
        navigate("/", { replace: true });
      }, 3000);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="outerDiv">
      {showThankYou ? (
        <div className="overlay">
          <img src={Tick} alt="confirmicon" />
          <p className="tahnkyouheader">Thank you for joining our waitlist!</p>
          <p className="thankyousubheader">
            We'll be in touch soon with updates on your status
          </p>
        </div>
      ) : (
        <>
          <div className="leftDiv">
            
            <div className="registerHeading">Enter your information</div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleSubmit }) => (
                <Form id="registrationForm" onSubmit={handleSubmit}>
                  <div className="formRow">
                    <div className="inputGroup">
                      <label htmlFor="firstName">First Name:</label>
                      <Field type="text" id="firstName" name="firstName" />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="inputGroup">
                      <label htmlFor="lastName">Last Name:</label>
                      <Field type="text" id="lastName" name="lastName" />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="inputGroup">
                      <label htmlFor="phoneNumber">Phone Number:</label>
                      <Field
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        onKeyPress={(e) => {
                          const pattern = /[0-9]/;
                          const inputChar = String.fromCharCode(e.charCode);
                          if (!pattern.test(inputChar)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="inputGroup">
                      <label htmlFor="email">Email:</label>
                      <Field type="email" id="email" name="email" />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="inputGroup">
                      <label htmlFor="city">City:</label>
                      <Field type="text" id="city" name="city" />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="inputGroup">
                      <label htmlFor="dob">Date of Birth:</label>
                      <Field type="date" id="dob" name="dob" />
                      <ErrorMessage
                        name="dob"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="inputGroup">
                      <label htmlFor="runningYears">
                        How long have you been running (in years):
                      </label>
                      <Field
                        type="number"
                        id="runningYears"
                        name="runningYears"
                        min="0"
                        onKeyDown={(e) =>
                          (e.key === "-" ||
                            e.key === "+" ||
                            e.key === "e" ||
                            e.key === "E") &&
                          e.preventDefault()
                        }
                      />
                      <ErrorMessage
                        name="runningYears"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="inputGroup">
                      <label htmlFor="longestDistance">
                        The longest distance you have run so far (in km):
                      </label>
                      <Field
                        type="number"
                        id="longestDistance"
                        name="longestDistance"
                        min="0"
                        onKeyDown={(e) =>
                          (e.key === "-" ||
                            e.key === "+" ||
                            e.key === "e" ||
                            e.key === "E") &&
                          e.preventDefault()
                        }
                      />
                      <ErrorMessage
                        name="longestDistance"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="formRow">
                    <div className="inputGroup">
                      <label htmlFor="reasonToParticipate">
                        Why do you wish to participate:
                      </label>
                      <Field
                        as="textarea"
                        rows="3"
                        id="reasonToParticipate"
                        name="reasonToParticipate"
                        style={{ resize: "none" }}
                      />
                      <ErrorMessage
                        name="reasonToParticipate"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="mainButton" >
                  <div id="submitBtn">
                    <div
                      className="register_race_join_btn_div"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <div className="register_race_join_btn_overlay1"></div>
                      <div className="register_race_join_btn_overlay2"></div>
                      <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? (
                          <div className="loader"></div> // Render loader when submitting
                        ) : (
                          "Join Waitlist"
                        )}
                      </button>
                    </div>
                  </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

        </>
      )}
    </div>
  );
};

export default Register;


