export const faqs = {
    race: [
        {
            id: 1,
            ques: "Who is this race for?",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget ",
        },
        {
            id: 2,
            ques: "Are the spots limited per race?",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 3,
            ques: "What do categories mean",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 4,
            ques: "Can I participate in more than one category",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 5,
            ques: "Can I participate in more than one city",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 6,
            ques: "Are spectators allowed",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 7,
            ques: "How do I know my start time for the event day",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 8,
            ques: "Can I choose my running slot",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 9,
            ques: "Is there a briefing on site",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 10,
            ques: "What facilities can I expect at the venue",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 11,
            ques: "Do you offer cancellation and refunds",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 12,
            ques: "How do I get my photographs post race",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 13,
            ques: "How do you capture the timing",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 14,
            ques: "How long does a typical race take",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 15,
            ques: "Can I get a discount",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
    ],
    result: [
        {
            id: 16,
            ques: "How does one prepare for TYR",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 17,
            ques: "I am a gym owner, how do I parter with TYR",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 18,
            ques: "Do you have any training partners",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
    ],
    prize: [
        {
            id: 19,
            ques: "How does one win the cash prize?",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
        {
            id: 20,
            ques: "What is the prize I can win",
            ans: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget",
        },
    ],
};

export const faqsQues = [
    "Who is this race for?",
    "How does one win the cash prize?",
    "What is the prize I can win",
    "How does one prepare for TYR",
    "Are the spots limited per race?",
    "What do categories mean",
    "Can I participate in more than one category",
    "Can I participate in more than one city",
    "Are spectators allowed",
    "How do I know my start time for the event day",
    "Can I choose my running slot",
    "Is there a briefing on site",
    "What facilities can I expect at the venue",
    "Do you offer cancellation and refunds",
    "I am a gym owner, how do I parter with TYR",
    "How do I get my photographs post race",
    "How do you capture the timing",
    "How long does a typical race take",
    "Do you have any training partners",
    "Can I get a discount",
];