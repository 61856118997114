import React from "react";
import "../one/one.css";

const One = () => {
  return (
    <div className="one_wrapper">
      <div className="one_heading_div">
        <p className="one_heading">ONE</p>
      </div>
      <div className="one_para_div">
        <p>
          The human spirit is an awe-inspiring force that knows no bounds,
          propelling us to soar beyond the constraints of our perceived
          limitations. It's a beacon of resilience, an unwavering flame of
          determination that flickers even in the darkest of times. When faced
          with challenges that seem insurmountable, it's the human spirit that
          ignites within us, urging us to push forward with unwavering resolve.
        </p>
        <p>
          Think of the countless individuals throughout history who have defied
          the odds, who have risen from the depths of despair to achieve
          greatness. Their stories resonate with us because they speak to the
          indomitable nature of the human spirit. From the courageous explorers
          who charted unknown territories to the relentless innovators who
          revolutionized industries, each one has demonstrated the remarkable
          capacity of the human spirit to transcend barriers and achieve the
          extraordinary.
        </p>
      </div>
    </div>
  );
};

export default One;
