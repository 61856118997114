// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage_main_container {
    width: 100%;
    height: 100%;
    position: relative;
    background: #151615 !important;
}

.homepage_header_div {
    width: 100%;
    position: fixed;
    z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/features/homePage/homePage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,eAAe;IACf,WAAW;AACf","sourcesContent":[".homepage_main_container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n    background: #151615 !important;\n}\n\n.homepage_header_div {\n    width: 100%;\n    position: fixed;\n    z-index: 99;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
