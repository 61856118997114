export const text = [
    {
        about_us: [
            {
                heading: "About Us",
                paraOne: "From the makers of “The Devils Circuit” and “The Yoddha Race” comes another disruptive participative format. This race combines endurance and pace in a unique format that will test you physically and mentally. Created for the toughest, you will race alongside many but your true competition will only be you.",
                paraTwo: "Our mission is to make extraordinary experiences in India, for the world. Focused on building brands in the participative space, we build communities of like- minded people who all align on a common goal – personal excellence.",
                paraThree: "Embedded within our core philosophy is the profound belief that personal excellence is a quest and that we all have our own journeys to traverse. By creating platforms that allow individuals to compete on, we aim to bring recognition to the ordinary people achieving extraordinary feats. Join us as we embark on this quest for greatness, where every step taken is a testament to the strength of the human spirit and the power of individual determination. Welcome to a realm where challenges transform into triumphs and ordinary becomes extraordinary."
            }
        ],
        franchise: [
            {
                heading: "Franchise Opportunity",
                paraOne: "The one finisher is a unique concept that brings to the forefront not only an athletes physical prowess but also their mental strength! Created for those who constantly want to push the limits of their endurance, the one finisher appeals to the mavericks, the ones who are not ordinary, who are all about being the best versions of themselves.",
                paraTwo: "If you wish to host this unique competition in your geography and want to understand the licensing opportunity, get in touch with us on ",
                email: "franchise@theonefinisher.com"
            }
        ]
    }
]