import React, { useState } from "react";
import "../header/header.css";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import Logo from "../../../assets/homepage/logo/logo.svg";

const Header = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <div>
      <div className="header_main_container">
        {!showDrawer && (
          <Link to="/">
            <div className="header_drawer_logo_div">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
        )}
        {/* <div className="header_btn_list_div">
          <ul>
            <Link style={{textDecoration: "none"}} to="/race-concept">
            <li className="header_btn_list_li">Race-Concept</li>
            </Link>

            <Link style={{textDecoration: "none"}} to="/shop">
            <li className="header_btn_list_li">Shop</li>
            </Link>

            <Link style={{textDecoration: "none"}} to="/about-Us">
            <li className="header_btn_list_li">About-Us</li>
            </Link>

            <Link style={{textDecoration: "none"}} to="/">
            <li className="header_btn_list_li">Contact-Us</li>
            </Link>
          </ul>
        </div> */}
        <Link to="/register">
          <div className="header_join_btn_div">
            <button>Join Waitlist</button>
          </div>
        </Link>

        {!showDrawer && (
          <div
            className="header_menu_btn_div"
            onClick={() => setShowDrawer(true)}
          >
            <HiOutlineMenuAlt4 className="header_menu_btn_icon" />
          </div>
        )}
      </div>

      {showDrawer && (
        <Drawer
          anchor="right"
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          onOpen={() => setShowDrawer(true)}
        >
          <div className="header_drawer_main">
            <div className="header_drawer_inner">
              <div className="header_drawer_header">
                <Link to="/">
                  <div className="header_drawer_logo_div">
                    <img src={Logo} alt="logo" />
                  </div>
                </Link>
                <div
                  className="header_drawer_close_btn_div"
                  onClick={() => setShowDrawer(false)}
                >
                  <IoCloseSharp
                    className="header_drwawer_menu_btn_icon"
                  />
                </div>
              </div>

              {/* <div className="header_drawer_list_div">
                <ul>
                  <Link style={{ textDecoration: "none" }} to="/race-concept">
                    <li className="header_drawer_li">Race-Concept</li>
                  </Link>

                  <Link style={{ textDecoration: "none" }} to="/">
                    <li className="header_drawer_li">Shop</li>
                  </Link>

                  <Link style={{ textDecoration: "none" }} to="/aout-us">
                    <li className="header_drawer_li">About-Us</li>
                  </Link>

                  <Link style={{ textDecoration: "none" }} to="/">
                    <li className="header_drawer_li">Contact-Us</li>
                  </Link>
                </ul>
              </div> */}

              <Link to="/register">
                <div className="header_drawer_join_btn_div">
                  <button>Join Waitlist</button>
                </div>
              </Link>
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default Header;
