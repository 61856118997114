
import { text } from '../../assets/text/text';
import PageHead from '../../components/common/pageHead/pageHead';
import franchise from '../../assets/franchise.png';
import Footer from '../../components/common/footer/Footer';
import { useEffect } from 'react';
function Franchise() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <PageHead
                heading={text[0].franchise[0].heading}
                paraTwo={text[0].franchise[0].paraOne}
                image_url={franchise}
                paraThree={text[0].franchise[0].paraTwo}
                email={text[0].franchise[0].email}
            />
            <Footer />
        </>
    )
}
export default Franchise;