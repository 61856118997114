import { useEffect } from "react";
import aboutUs from '../../assets/abousImages/aboutUs.png';
import PageHead from "../../components/common/pageHead/pageHead";
import { text } from '../../assets/text/text';
import { useInView } from "react-intersection-observer";
import './aboutUs.css';
import Footer from "../../components/common/footer/Footer";
const AboutUs = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHead
        heading={text[0].about_us[0].heading}
        paraOne={text[0].about_us[0].paraOne}
        image_url={aboutUs}
        paraTwo={text[0].about_us[0].paraTwo}
        paraThree={text[0].about_us[0].paraThree}
      />
      <div className='bold_text_wrapper'>
        <div className='bold_text_inner' ref={ref}>
          <p className={`bold_text_p ${inView ? 'animate' : ''}`}>
            ONE MORE KILOMETER, <br />
            ONE STEP CLOSER <br />
            TO VICTORY, <br />
            ONE STEP CLOSER <br />
            TO BECOMING <br />
            THE ONE FINISHER
          </p>
        </div>
      </div>
      <Footer />
    </>

  );
};

export default AboutUs;
