import React, { useState, useEffect, useRef } from "react";
import "../bannerSection/bannerSection.css";
import Slider from "react-slick";

const BannerSection = () => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    arrows: false,
    draggable: false,
    pauseOnHover: false,
    // fade: true,
    speed: 500,
  };

  var aspectRatio = 1558 / 668;
  var cardHeight = screenWidth / aspectRatio;

  const actualSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", actualSize);
    return () => {
      window.removeEventListener("resize", actualSize);
    };
  }, []);

  return (
    <div className="banner_section_wrapper">
      <div className="banner_section_inner_div">
      <div className="banner_section_slider_main">
        <Slider {...settings}>
          <div className="banner_section_card">
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/img1+(5).png"
              alt="img"
            />
          </div>
          <div className="banner_section_card">
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/img1+(5).png"
              alt="img"
            />
          </div>
          <div className="banner_section_card">
            <img
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/img1+(5).png"
              alt="img"
            />
          </div>
        </Slider>
      </div>
      <div className="banner_section_overlay_div">
        <div className="banner_section_overlay_inner_div">
        <p className="banner_section_overlay_text_1">
          Come Experience <br />
          being alive at
        </p>
        <p className="banner_section_overlay_text_2">One Finisher</p>
        </div>
      </div>
      <img className="ShadowImg" src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/shadow.png" alt="img" />

    </div>
    </div>
  );
};

export default BannerSection;
