import React from 'react'
import './App.css';
import Routing from './routing/Routing';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
    <Routing />
    <ToastContainer />
    </>
  )
}

export default App