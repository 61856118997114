import React from 'react'
import "../boldtext/boldText.css";

import { useInView } from "react-intersection-observer";

const Boldtext = () => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.5,
    });
  
    return (
      <div className='bold_text_wrapper'>
        <div className='bold_text_inner' ref={ref}>
          <p className={`bold_text_p ${inView ? 'animate' : ''}`}>
            Every Hour, <br />
            ON the HOUR, <br />
            7km. <br />
            How Far Will <br />
            You Go?
          </p>
        </div>
      </div>
    );
  };
  
  export default Boldtext;