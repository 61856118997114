import { useState, useEffect } from "react";
import './faq.css';
import ControlledAccordions from "./faqAccordian";
import { faqs } from "../../assets/text/faqData";
import PageHead from "../../components/common/pageHead/pageHead";
import Footer from "../../components/common/footer/Footer";
function FAQ() {
    const [isSelected, setIsSelected] = useState("race");
    const [faqData, setFaqData] = useState(faqs.race);

    useEffect(() => {
        if (isSelected === "race") {
            setFaqData(faqs.race);
        }
        if (isSelected === "result") {
            setFaqData(faqs.result);
        }
        if (isSelected === "prize") {
            setFaqData(faqs.prize);
        }
    }, [isSelected]);



    return (
        <>
            <div className="aboutUsContainer">
                <div><label>Frequently Asked Questions
                </label>
                    <div></div>
                </div>
            </div>
            <div className="faqContainer">
                <div className="mainFAQ">
                    <div className="faq">
                        {isSelected !== null && (
                            <>
                                <div>
                                    <div className="navbar">
                                        <div className={isSelected === "race" ? "tabActive" : "disable"} onClick={() => setIsSelected("race")}>Race</div>
                                        <div className={isSelected === "result" ? "tabActive" : "disable"} onClick={() => setIsSelected("result")}>Result</div>
                                        <div className={isSelected === "prize" ? "tabActive" : "disable"} onClick={() => setIsSelected("prize")}>Prizes</div>
                                    </div>

                                    <div className="border"></div>
                                </div>
                            </>
                        )}
                        <div className="faqData">
                            <div className="faqAccordians">
                                <ControlledAccordions faqData={faqData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default FAQ;