import React from 'react'
import "../maskVideo/maskVideo.css";

const MaskVideo = () => {
  return (
    <div className='mask_video_wrapper'>
        <div className='mask_video_inner_div'>
           <div className='mask_video_section'>
           <video
          controls={false}
          preload="true"
          loop={true}
          autoPlay={true}
          muted
          playsInline
          // style={{ filter: 'grayscale(100%)' }} 
        >
          <source
            src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisher/-6afc-47c0-ac9a-428faf402c90.mp4"
            type="video/mp4"
          />
        </video>
           </div>

           <div className='mask_cards_div'>
             <div className='mask_card'>
                <div className='mask_card_major'></div>
                <div className='mask_card_minor'></div>
             </div>

             <div className='mask_card'>
                <div className='mask_card_minor'></div>
                <div className='mask_card_major'></div>
             </div>

             <div className='mask_card'>
                <div className='mask_card_major'></div>
                <div className='mask_card_minor'></div>
             </div>

             <div className='mask_card'>
                <div className='mask_card_minor'></div>
                <div className='mask_card_major'></div>
             </div>

           </div>
        </div>
    </div>
  )
}

export default MaskVideo