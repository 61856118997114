import React from "react";
import "../joinBtn/joinBtn.css";
import { Link } from "react-router-dom";

const JoinBtn = ({ videoUrl }) => {
  return (
    <div className="join_btn_wrapper">
      <div className="join_btn_inner_div">
        <div className="join_btn_img_div">
          <img className="join_btn_top_img" src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/topShadow.png" alt="img" />
          <img className="join_btn_main_img" src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/waitList.png" alt="img" />
          <img className="join_btn_bottom_img" src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/shadow.png" alt="img" />
        </div>
        <div className="join_btn_div">
          {/* <p className="join_btn_div_p1">Kedarnath Sahni Auditorium, Delhi</p> */}
          <p className="join_btn_div_p2"></p>
          {/* 21 nov 2024 */}
          <p className="join_btn_div_p3">Join the Waiting List to see if you have what it takes </p>
          <Link to="/register">
            <div className="join_btn_sub_div">
              <button>Join Waitlist</button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default JoinBtn;
