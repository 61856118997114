import React from 'react'
import "../common/tempfooter.css";
import Logo from "../../assets/homepage/logo/logo.svg";
import { Link } from 'react-router-dom';

const TempFooter = () => {
  return (
    <div className='temp_footer_wrapper'> 
        <div className='temp_footer_inner'>
        <Link to="/" style={{textDecoration: "none"}}>
           <div className='temp_footer_logo_div'>
            <img src={Logo} alt='logo' />
           </div>
           </Link>

           <div className='temp_footer_links'>
             <Link to="/about-us" style={{textDecoration: "none"}}> <p className='temp_footer_btn'>About-Us</p> </Link>
             {/* <Link to="/contact-us" style={{textDecoration: "none"}}> <p className='temp_footer_btn'>Contact-Us</p> </Link> */}
           </div>
        </div>
        </div> 
  )
}

export default TempFooter