import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import './faq.css';

export default function ControlledAccordions({ faqData }) {
    return (
        <div>
            {faqData.map((item) => (
                <div key={item.id}>
                    <Accordion className="acordianMain">
                        <AccordionSummary
                            className="accordian"
                            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <span className="accordianques">{item.ques}</span>
                        </AccordionSummary>
                        <div className="accordianans">{item.ans}</div>
                    </Accordion>
                    <br />
                </div>
            ))}
        </div>
    );
}