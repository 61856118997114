import React, {useState, useEffect} from "react";
import "../adventure/adventure.css";
import { Link } from "react-router-dom";

const Adventure = () => {
    const [screenWidth, setScreenWidth] = useState(window.screen.width);


    var aspectRatio = 1050 / 472;
    var cardHeight = screenWidth / aspectRatio;
  
    const actualSize = () => {
      setScreenWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener("resize", actualSize);
      return () => {
        window.removeEventListener("resize", actualSize);
      };
    }, []);

  return (
    <div className="adventure_wrapper">
      <p className="adventure_heading">Ready for Adventure?</p>
      <p className="adventure_para">
        A test of endurance and determination like no other. Race against time,
        conquer obstacles, and emerge as the sole victor in this ultimate
        challenge of resilience
        <Link to="/race-concept">
        <button className="adventure_btn">Learn More</button>
        </Link>
      </p>
      <div className="adventure_video_div">
      <video
            className="adventure_video"
            controls={false}
            preload="true"
            loop={true}
            autoPlay={true}
            muted
            playsInline
            style={{height:cardHeight}}
          >
            <source
              src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisherfinal/video1.mp4"
              type="video/mp4"
            />
          </video>
      </div>
    </div>
  );
};

export default Adventure;
