import "./pageheading.css";
const PageHeading = ({heading}) => {
  return (
    <div class="page_heading">
      <p>{heading}</p>
    </div>
  );
};

export default PageHeading;
