import React, { useState } from "react";
import "../getInTouch/getInTouch.css";
import { Modal, Box } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

const GetInTouch = ({ open, onClose }) => {
  const [spinState, setSpinState] = useState(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Name should be atleast 2 characters")
      .max(100, "Name should be less 100 characters")
      .required("Name is required"),
    Email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    Message: Yup.string()
      .min(2, "Message should be atleast 2 characters")
      .max(500, "Message should be less 500 characters")
      .required("Message is required"),
  });

  const initialValues = {
    fullName: "",
    Email: "",
    Message: "",
  };

  const handleSubmit = async (values) => {
    setSpinState(true);
    try {
      await axios.post("https://submit-form.com/xuMEW6Y5P", values);
      setSpinState(false);
      toast.success("Message sent successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      setSpinState(false);
      console.error("Error submitting form:", error);
      toast.warn("Something went wrong, Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div className="getintouch_wrapper">
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="getintouch_modal"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#151615;",
            boxShadow: 24,
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form className="getintouch_form" onSubmit={handleSubmit}>
                <p className="getintouch_heading">Get In Touch</p>
                <div className="getintouch_field_box">
                  <label htmlFor="fullName">Name*</label>
                  <Field
                    type="text"
                    id="fullName"
                    name="fullName"
                    className="getintouch_field"
                    placeholder="Enter Name"
                  />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="getintouch_invalid_feedback"
                  />
                </div>

                <div className="getintouch_field_box">
                  <label htmlFor="Email">Email*</label>
                  <Field
                    type="email"
                    id="Email"
                    name="Email"
                    className="getintouch_field"
                    placeholder="Enter Email"
                  />
                  <ErrorMessage
                    name="Email"
                    component="div"
                    className="getintouch_invalid_feedback"
                  />
                </div>

                <div className="getintouch_field_box">
                  <label htmlFor="Message">Message*</label>
                  <Field
                    as="textarea"
                    type="text"
                    id="Message"
                    name="Message"
                    className="getintouch_text_area_field"
                    placeholder="Enter Message"
                  />
                  <ErrorMessage
                    name="Message"
                    component="div"
                    className="getintouch_invalid_feedback"
                  />
                </div>

                <button
                  type="submit"
                  className="getintouch_join_btn_div"
                  style={{
                    background: "none",
                    cursor: !spinState ? "pointer" : "none",
                  }}
                >
                  <div className="getintouch_join_btn_inner_div">
                    {spinState ? (
                      <CircularProgress
                        className="custom-progress"
                        style={{ color: "black" }}
                        size={25}
                        thickness={3}
                      />
                    ) : (
                      <span>Send Message</span>
                    )}
                  </div>
                </button>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default GetInTouch;
