// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_heading {
    position: relative;
    display: inline-block;
    color: #fff;
    font-family: Inter;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
     padding:  0px !important;
    margin: 88px 0px 41px 100px;
  }
  
  .page_heading::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 60%;
    padding: 0px !important;
    background-color: #206d23;
    z-index: -1;
    margin-left: -5px;
  }

  @media screen and (max-width: 650px) {
    .page_heading {
        margin: 88px 0px 41px 30px;
        font-size: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/pageHeading/pageheading.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;KAClB,wBAAwB;IACzB,2BAA2B;EAC7B;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,UAAU;IACV,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE;QACI,0BAA0B;QAC1B,eAAe;IACnB;AACJ","sourcesContent":[".page_heading {\n    position: relative;\n    display: inline-block;\n    color: #fff;\n    font-family: Inter;\n    font-size: 52px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal; \n     padding:  0px !important;\n    margin: 88px 0px 41px 100px;\n  }\n  \n  .page_heading::before {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 90%;\n    height: 60%;\n    padding: 0px !important;\n    background-color: #206d23;\n    z-index: -1;\n    margin-left: -5px;\n  }\n\n  @media screen and (max-width: 650px) {\n    .page_heading {\n        margin: 88px 0px 41px 30px;\n        font-size: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
