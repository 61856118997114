import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "../features/homePage/HomePage";
import AboutUs from "../features/aboutUs/AboutUs";
import ContactUs from "../features/contactUs/ContactUs";
import RaceConceptLearnMore from "../features/raceconceptlearnmore/raceconceptlearnmore";
import Register from "../features/register/register";
import Franchise from "../features/franchise/franchise";
import FAQ from "../features/FAQ/faq";

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/race-concept" element={<RaceConceptLearnMore />} />
        <Route path="/franchise" element={<Franchise />} />
        <Route path="/" element={<FAQ />} />
        {/* faq" */}
      </Routes>
    </Router>
  );
};

export default Routing;
