// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #151615;
  color: #fff;
}
.acordianMain {
  background-color: #151615 !important;
  border-radius: 4px !important;
  border: 2px solid #3e3d3d !important;
  padding: 10px 50px !important;
}

.accordian {
  min-height: 40px !important;
  border-bottom: 1px solid transparent !important;
  padding: 0px 0px 7px 0px !important;
  margin: 0px !important;
}

.accordian > div {
  margin: 0px !important;
}
@media screen and (max-width: 600px) {
  .acordianMain {
    padding: 10px 10px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,uBAAuB;EACvB,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,oCAAoC;EACpC,6BAA6B;EAC7B,oCAAoC;EACpC,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;EAC3B,+CAA+C;EAC/C,mCAAmC;EACnC,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: sans-serif;\n  background-color: #151615;\n  color: #fff;\n}\n.acordianMain {\n  background-color: #151615 !important;\n  border-radius: 4px !important;\n  border: 2px solid #3e3d3d !important;\n  padding: 10px 50px !important;\n}\n\n.accordian {\n  min-height: 40px !important;\n  border-bottom: 1px solid transparent !important;\n  padding: 0px 0px 7px 0px !important;\n  margin: 0px !important;\n}\n\n.accordian > div {\n  margin: 0px !important;\n}\n@media screen and (max-width: 600px) {\n  .acordianMain {\n    padding: 10px 10px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
