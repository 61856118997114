import React, { useRef } from "react";
import "../story/story.css";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Story = () => {
  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };

  const previous = () => {
    sliderRef.slickPrev();
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="story_prev"
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <p className="story_prev_arrow">
          <ArrowBackIosIcon fontSize="30px" />
        </p>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="story_next"
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <p className="story_next_arrow">
          <ArrowForwardIosIcon fontSize="30px" />
        </p>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    arrows: false,
    // prevArrow: <SampleNextArrow />,
    // nextArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
        },
      },
    ],
  };

  return (
    <div className="story_wrapper">
      <div className="story_inner_div">
        <div className="story_slider_div">
          <div className="story_header">
            <p className="story_heading">Stories of human fortitude</p>
            <div className="story_slider_btns_div">
              <button className="story_slider_btn" onClick={previous}>
                <ArrowBackIosIcon
                  className="story_slider_btn_icon"
                  fontSize="50px"
                />
              </button>
              <button className="story_slider_btn" onClick={next}>
                <ArrowForwardIosIcon
                  className="story_slider_btn_icon"
                  fontSize="50px"
                />
              </button>
            </div>
          </div>
          <div className="story_cards">
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
            >
              <div className="story_card">
                <div className="story_card_img_div">
                  <img
                    src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/img1+(5).png"
                    alt="img"
                  />
                </div>
                <div className="story_card_info_div">
                  <p>
                    But perhaps the most incredible aspect of the human perhaps
                    the most incredible.. Read More
                  </p>
                </div>
              </div>
              <div className="story_card">
                <div className="story_card_img_div">
                  <img
                    src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/img2+(3).png"
                    alt="img"
                  />
                </div>
                <div className="story_card_info_div">
                  <p>
                    But perhaps the most incredible aspect of the human perhaps
                    the most incredible.. Read More
                  </p>
                </div>
              </div>
              <div className="story_card">
                <div className="story_card_img_div">
                  <img
                    src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/img3+(2).png"
                    alt="img"
                  />
                </div>
                <div className="story_card_info_div">
                  <p>
                    But perhaps the most incredible aspect of the human perhaps
                    the most incredible.. Read More
                  </p>
                </div>
              </div>
              <div className="story_card">
                <div className="story_card_img_div">
                  <img
                    src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/img1+(5).png"
                    alt="img"
                  />
                </div>
                <div className="story_card_info_div">
                  <p>
                    But perhaps the most incredible aspect of the human perhaps
                    the most incredible.. Read More
                  </p>
                </div>
              </div>
              <div className="story_card">
                <div className="story_card_img_div">
                  <img
                    src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/img2+(3).png"
                    alt="img"
                  />
                </div>
                <div className="story_card_info_div">
                  <p>
                    But perhaps the most incredible aspect of the human perhaps
                    the most incredible.. Read More
                  </p>
                </div>
              </div>
              <div className="story_card">
                <div className="story_card_img_div">
                  <img
                    src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/img3+(2).png"
                    alt="img"
                  />
                </div>
                <div className="story_card_info_div">
                  <p>
                    But perhaps the most incredible aspect of the human perhaps
                    the most incredible.. Read More
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
