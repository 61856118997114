import React, { useState } from "react";
import "../footer/footer.css";
import { Link } from "react-router-dom";
import Logo from "../../../assets/homepage/logo/logo.svg";

import GetInTouch from "../getInTouch/GetInTouch";
import BottomFooter from "./BottomFooter";

import XImg from "../../../assets/social/x.svg";
import FbImg from "../../../assets/social/fb.svg";
import InstaImg from "../../../assets/social/insta.svg";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="footer_wrapper">
        <div className="footer_main_container">
          <div className="footer_left">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img className="footer_logo" src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="footer_right">
            <div className="footer_card">
              <div className="footer_card_heading_div">
                <p>Race</p>
              </div>
              <div className="footer_card_main_div">
                <ul>
                  <Link
                    className="footer_link"
                    style={{ textDecoration: "none" }}
                    to="/race-concept"
                  >
                    Race-Concept
                  </Link>
                  <Link
                    className="footer_link"
                    style={{ textDecoration: "none" }}
                    to="/register"
                  >
                    Join Waitlist
                  </Link>
                  {/* <Link
                    className="footer_link"
                    style={{ textDecoration: "none" }}
                  >
                    What’s Included
                  </Link> */}
                </ul>
              </div>
            </div>

            <GetInTouch open={isModalOpen} onClose={handleCloseModal} />

            <div className="footer_card">
              <div className="footer_card_heading_div">
                <p>Athlete</p>
              </div>
              <div className="footer_card_main_div">
                <ul>
                  <li className="footer_link">Pictures</li>
                  <li className="footer_link">Rules</li>
                  <li className="footer_link">Blogs</li>
                </ul>
              </div>
            </div>

            <div className="footer_card">
              <div className="footer_card_heading_div">
                <p>Support</p>
              </div>
              <div className="footer_card_main_div">
                <ul>
                  <Link
                    to="/about-us"
                    className="footer_link"
                    style={{ textDecoration: "none" }}
                  >
                    About Us
                  </Link>
                  <Link
                    // to="/faq"
                    style={{ textDecoration: "none" }}
                    className="footer_link"
                  >
                    FAQ{" "}
                  </Link>

                  <Link
                    onClick={handleOpenModal}
                    className="footer_link"
                    style={{ textDecoration: "none" }}
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="/franchise"
                    className="footer_link"
                    style={{ textDecoration: "none" }}
                  >
                    Franchise opportunity
                  </Link>
                </ul>
              </div>
            </div>

            <div className="footer_card">
              <div className="footer_card_heading_div">
                <p>Follow</p>
              </div>
              <div className="footer_card_main_div">
                <div className="footer_social_icons">
                  <a href="/" className="footer_social_icon">
                    <img src={FbImg} alt="icon" />
                  </a>
                  <a href="/" className="footer_social_icon">
                    <img src={XImg} alt="icon" />
                  </a>
                  <a href="/" className="footer_social_icon">
                    <img src={InstaImg} alt="icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomFooter />
    </>
  );
};

export default Footer;
