import React from "react";
import { useEffect } from "react";
import Header from "../../components/common/header/Header";
import Footer from "../../components/common/footer/Footer";
import "./raceconcept.css";
import raceconcept from "../../assets/race_concept.png";
import waitlist from "../../assets/waitlist.gif";
import JoinBtn from "../../components/homePage/joinBtn/JoinBtn";
import { useNavigate } from "react-router-dom";
import TempFooter from "../../components/common/TempFooter";
import PageHeading from "../../components/common/pageHeading/PageHeading";

const RaceConceptLearnMore = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const videoUrl =
    "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/-0bf9-4128-a015-464c0c10789a.mp4";

  return (
    <>
      <Header />
      <div className="outerContainer">
        <PageHeading heading={"RaceConcept"} />
        <div className="race_concept_para">
          In this unique race, the ultimate goal is not to be the fastest or to
          attain a specific time, but rather to be the sole finisher among all
          participants. It's a race against the odds, against unforeseen
          challenges, and against the natural inclination to compete with
          others. The One Finisher race is a testament to endurance, resilience,
          and sheer determination.
        </div>
        <div className="race_concept_para1">
          The concept of the "One Finisher" race is quite intriguing. In a
          typical race, participants strive for victory or aim to achieve their
          personal best times, with some being recognized as winners and others
          as runners-up. However, in the One Finisher race, the dynamic shifts
          dramatically.
        </div>
        <video
          className="race-concept-video"
          controls={false}
          preload="true"
          loop={true}
          autoPlay={true}
          muted
          playsInline
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
        <div className="race_concept_para2">
          What sets the One Finisher race apart is its emphasis on individual
          achievement within a collective experience. While everyone starts the
          race together, each participant must navigate their own path, facing
          their own struggles and triumphs. It's not about outpacing others but
          about pushing oneself beyond one's limits. The title of "One Finisher"
          carries immense prestige and honor, symbolizing an unparalleled feat
          of determination and perseverance. The victor of the One Finisher race
          isn't just a winner; they are a testament to the human spirit's
          capacity to overcome adversity and achieve the seemingly impossible.
        </div>
        <div className="ready_heading">READY SET GO!</div>
        <div className="ready_para">
          Start your race at 7am Run 7 kms within the hour Balance time is your
          rest time For example, if you run 7 kms in 42 minutes then you can
          rest for 18 minutes Start again at the hour and run 7 kms Balance time
          is your rest time Keep going till you are the only one left That is
          how you become "The One Finisher"
        </div>
      </div>

      <Footer />
    </>
  );
};

export default RaceConceptLearnMore;
