import React from "react";
import "./homePage.css";
import Header from "../../components/common/header/Header";
import JoinBtn from "../../components/homePage/joinBtn/JoinBtn";
import Finisher from "../../components/homePage/finisher/Finisher";
import One from "../../components/homePage/one/One";
import Footer from "../../components/common/footer/Footer";
import Story from "../../components/homePage/story/Story";
import MaskVideo from "../../components/homePage/maskVideo/MaskVideo";
import Boldtext from "../../components/homePage/boldtext/Boldtext";

import TempFooter from "../../components/common/TempFooter";
import BannerSection from "../../components/homePage/bannerSection/BannerSection";
import Adventure from "../../components/homePage/adventure/Adventure";
import Valour from "../../components/homePage/valour/Valour";

const HomePage = () => {
  // Define the video URL
  const videoUrl =
    "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/OneFinisher/-c362-4f46-b648-2fd1c142454f.mp4";

  return (
    <div className="homepage_main_container">
      <div className="homepage_header_div">
        <Header />
      </div>
      <BannerSection />
      <Adventure />

      <Boldtext />
      <Valour />
      <One />

      {/* Pass the video URL to the JoinBtn component */}
      <JoinBtn />
      <Finisher />
      <MaskVideo />

      {/* <Story />  */}
      <Footer />
      {/* <TempFooter /> */}
    </div>
  );
};

export default HomePage;
